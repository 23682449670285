/* Place your extensions here to make migration to a new release easier. */

.layout-wrapper {
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.App{
  height:100%;
  margin-bottom: 60px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;

}