* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: 'Open+Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #131a1c;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
}

.draggable-main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: 'Open+Sans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #131a1c;
}

.content {
  position: relative;
  width: 100%;
  height: 40px;
  height: 100%;
    min-height: 600px;
}

.content > div {
  text-align: left;
  position: absolute;
  width: 100%;
  height: 40px;
  overflow: auto;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 30px;
  padding-left: 32px;
  background: #e84e0f;
  text-transform: uppercase;
  letter-spacing: 2px;
  touch-action: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.porange {
  font-size: 2em;
  font-weight: 700;
  color: #e84e0f;
}
.pwhite {
  font-size: 2em;
  font-weight: 700;
  color: white;
}

.circularpic {
  border-radius: 50%;
  width: 150px; /* width of container */
  height: 150px; /* height of container */
  object-fit: cover;
}

.senden {
  color: #e85222;
  border-color: #fefefe;
  background-color: #fefefe;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  margin-right: 0px;
  padding: 12px 20px;
  padding-left: 20px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 12px;
  -webkit-appearance: none;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

body .p-rating .p-rating-icon {
  font-size: 50px;
}

.p-rating {
  text-align: center;
}

.pcenter {
  text-align: center;
}

.employee-img-container{
  height: 180px;
  margin-bottom: 20px;
}
.employee-img-container>img{
  width: 50%;
  height: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
}
.rating-page-result{
  display: flex;
  justify-content: center;
  overflow:auto;

}
.rating-page-result>div{
  width: 100%;
  max-width: 600px;
}