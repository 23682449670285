* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
/*   max-height: 100vh; */
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
/*   max-height: 100vh; */
}
